 /* Bringing in the colors into CSS */
:root {
    --swatch-plain: #abb2bf;
    --swatch-background: #282c34;
    --swatch-background-dark: #151b24;
    --swatch-header: #727e96;
    --swatch-caret: #528bff;
    --swatch-pink: #e06c75; 
    --swatch-cyan: #56b6c2;
    --swatch-lime: #98c379;
    --swatch-purple: #c678dd;
    --swatch-brown: #d19a66;
    --swatch-gold: #e5c07b;
    --swatch-blue: #61afef;
    --swatch-comment: #5c6370;
}

body {
    background-color: var(--swatch-background);
  }
  
#root {
    font-family: "Lexend Exa";
    color: var(--swatch-plain);
    position: absolute;
    width: 100%;
    left: 0px;
    height: 100%;
    top: 0px;
    overflow-x: hidden;
    overflow-y: visible;
}

#app-root {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0%;
    left: 0%;
}
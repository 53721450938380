#layout {
    height  : 100%;
    width   : 100%;
    display : flex;
    position: absolute;
}

#sidebar {
    width   : 200px;
    float   : left;
    position: relative;
}

#main-body {
    width         : 100%;
    height        : 100%;
    position      : relative;
    overflow-y    : auto;
    overflow-x    : hidden;
    display       : flex;
    flex-direction: column;
}

#banner-container {
    width : 100%;
    height: 25vh;
}

#main-body-container {
    position: relative;
    width   : 90%;
    height  : calc(100% - 25vh);
    padding : 5%;
}

#page-banner-break {
    width           : 100%;
    height          : 1vh;
    background-color: var(--swatch-lime);
    float           : inline-end;
}

#layout-mobile {
    height  : 100%;
    width   : 100%;
    position: absolute;
}

#mobile-banner {}

.sidebar-mobile-open {
    position         : fixed;
    height           : 100vh;
    width            : 200px;
    float            : left;
    z-index          : 10;
    transform        : translateX(-200px);
    -webkit-transform: translateX(-200px);
}

.sidebar-mobile-closed {
    position: fixed;
    height  : 100vh;
    width   : 200px;
    float   : left;
    z-index : 10;
}

.close-button {
    position: fixed;
    float   : left;
    left    : 25px;
    top     : 7px;
    z-index : 12;
}

.darkened-mobile-open {
    position        : fixed;
    height          : 100vh;
    width           : 100vw;
    background-color: rgba(0, 0, 0, 0.75);
    z-index         : 9;
}

.darkened-mobile-close {
    position: fixed;
    height  : 0px;
    width   : 0px;
}

.slide-in {
    animation        : slide-in 0.5s forwards;
    -webkit-animation: slide-in 0.5s forwards;
}

.slide-out {
    animation        : slide-out 0.5s forwards;
    -webkit-animation: slide-out 0.5s forwards;
}

@keyframes slide-in {
    0% {
        transform: translateX(-200px)
    }

    100% {
        transform: translateX(0px);
    }
}

@-webkit-keyframes slide-in {
    0% {
        -webkit-transform: translateX(-200px)
    }

    100% {
        -webkit-transform: translateX(0px);
    }
}

@keyframes slide-out {
    0% {
        transform: translateX(0px);
    }

    100% {
        transform: translateX(-200px);
    }
}

@-webkit-keyframes slide-out {
    0% {
        -webkit-transform: translateX(0px);
    }

    100% {
        -webkit-transform: translateX(-200px);
    }
}